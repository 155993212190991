import React from "react";
import { Link } from "react-router-dom";

import Nav from "../layouts/nav";
import { ThemeContext } from "../../context/ThemeContext";

function VerifyStep4() {
       const { menuToggle } = React.useContext(ThemeContext);

    return (
        <>
          <div id="main-wrapper" className={` show ${
                menuToggle ? "menu-toggle" : ""
                }`}>
                    <Nav/>
                    <div className="content-body">
                        <div className="container-fluid">

            <div className="verification section-padding">
                <div className="container h-100"  data-aos="fade-up" data-aos-delay="000">
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-xl-7 col-12">
                            <div className="auth-form card">
                                <div className="card-body">
                                    <form
                                        action="#"
                                        className="identity-upload"
                                    >
                                        <div className="identity-content">
                                            
                                            <h4>
                                            <span className="icon icon-success mx-2">
                                                <i className="fa fa-check"></i>
                                            </span>
                                            تصاویر  با موفقیت  بارگذاری شدند</h4>
                                            <p>
                                                کارشناسان ما در اسرع وقت وضعیت حساب کاربری شما را بررسی خواهند نمود.
                                                با تشکر از شکیبایی شما
                                            </p>
                                        </div>

                                        <div className="text-center mb-4">
                                            <Link
                                                to={"./accounts"}
                                                type="submit"
                                                className="btn btn-success ps-5 pe-5"
                                            >
                                                ادامه
                                            </Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
            </div>
        </>
    );
}

export default VerifyStep4;
