import React from "react";
import ReactApexChart from "react-apexcharts";

class MarketOverviewChart extends React.Component {
  constructor(props) {
    super(props);
    const colors = ["#FFAB2D","#00ADA3", "#5e96da", "#b12021"]  
    console.log(Object.keys(props.data))
    
    this.state = {
      series: Object.keys(props.data).map(item=>{
        return {name: item, data: props.data[item].map(i=>{return i.price2}).reverse()}
      })
      ,
      options: {
        chart: {
          height: 360,
         
        },
        colors:colors.splice(0, Object.keys(props.data).length),
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        
        grid:{
			borderColor: '#EEEEEE',
		},
        yaxis: {
          labels: {
          style: {
            colors: '#787878',
            fontSize: '13px',
            fontFamily: 'Poppins',
            fontWeight: 100,
            transform: "transalteX(20px)"
            
          },
          formatter: function (value) {
            return value;
          }
		  },
		},
        xaxis: {
          type: "datetime",
          categories: Object.keys(props.data).length?props.data[Object.keys(props.data)[0]].map(i=>{let x = new Date(i.timestamp*1000);return x.toLocaleString() }).reverse(): [],
            labels:{
              style: {
                colors: '#000',
                fontSize: '12px',
                fontFamily: 'Poppins',
                fontWeight: 400
            },
            
		      }
        },
        tooltip: {
          x: {
            format: "dd/MM HH:mm",
          },
        },
      },
    };
  }

  render() {
    return (
      <div id="chart" className="market-line main-chart" dir="ltr">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="area"
          height={320}
        />
      </div>
    )
  }
}

export default MarketOverviewChart;
