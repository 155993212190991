import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import SettingsNav from '../components/custom/settings-nav';

import { ThemeContext } from "../../context/ThemeContext";
import Nav from "../layouts/nav";



function SettingsAccount() {
      const { menuToggle } = React.useContext(ThemeContext);

    const cards = useSelector(state => state.accounts.cards)
    const user = useSelector(state => state.session.user)
    const dispatch = useDispatch()
    React.useEffect(() => {
       dispatch({type: "UPDATE_MENU", payload: "settings"})
    }, [dispatch])
    return (
        <>

            <div id="main-wrapper" className={` show ${
                menuToggle ? "menu-toggle" : ""
                }`}>
                <Nav/>
                <div className="content-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12" >
                                <SettingsNav active="accounts"/>
                            </div>
                            <div className="col-12" data-aos="fade-up" data-aos-delay="200">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">حساب یا کارت های متصل</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="form">
                                            <ul className="linked_account">
                                                {cards.length?
                                                    cards.map((card, idx)=>{
                                                        return <li key={idx}>
                                                        <div className="row">
                                                            <div className="col-9">
                                                                <div className="d-flex mb-5">
                                                                    <span className="mx-2"><i className="fa fa-credit-card"></i></span>
                                                                    <div>
                                                                        <h5 className="mt-0 mb-1">بانک{" "}{card.bank}</h5>
                                                                        <p dir="ltr" className="mb-0"> {card.card.slice(0,4)}{"*".repeat(12)} کارت</p>
                                                                    </div>
                                                                
                                                                </div>
                                                            </div>
                                                            <div className="col-3 my-md-0 mt-2 mb-5">
                                                                <div className="verify">
                                                                    {card.status==="confirmed" ?
                                                                        <div className="verified d-flex mb-3">
                                                                            <span><i className="la la-check"></i></span>
                                                                            <h6>تایید شده</h6>
                                                                        </div>
                                                                        : 
                                                                        (
                                                                        card.status==="pending"? 
                                                                        <div className="not-verify d-flex mb-3">
                                                                            <span><i className="icofont-info"></i></span>
                                                                            <h6>در انتظار تایید</h6>
                                                                        </div>:
                                                                        <div className="not-verify d-flex mb-3">
                                                                            <span><i className="icofont-close-line"></i></span>
                                                                            <h6>تایید نشده</h6>
                                                                        </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    })
                                                :<p>حساب ثبت نشده است</p>}

                                                
                                            </ul>

                                            <div className="mt-5">
                                                {user&& user.authentication_status !=="accepted"?
                                                <>
                                                <Link to={'/panel/add-debit-card'} className="btn btn-success px-4 disabled" disabled>افزودن کارت اعتباری</Link>
                                                <small className="mx-3 text-danger">برای افزودن کارت باید حساب شما تایید شده باشد.
                                                    <Link className="mx-2 text-warning" to="/panel/verify-step-1">احراز هویت</Link>
                                                </small>
                                                </>
                                                :<Link to={'/panel/add-debit-card'} className="btn btn-success px-4">افزودن کارت اعتباری</Link>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
        </>
    )
}

export default SettingsAccount;