import React from "react";
import ReactApexChart from "react-apexcharts";

class CurrentChart extends React.Component {
	constructor(props) {
		
        super(props);		
        this.state = {
			colors:['#498bd9', '#ffab2d', '#70b944','#6647bf', '#aa0000'],
            series: props.values.map(t=>t.balance) ,
			
            options: {
				chart: {
					height: 350,
					type: 'radialBar',
					
				},
		
				plotOptions: {
					
					radialBar: {
						startAngle:-90,
						endAngle: 90,
						dataLabels: {
							name: {
								fontSize: '22px',
							},
							value: {
								fontSize: '20px',
								formatter:function(val, opt) {
								return val
							},
							},
						}
					},
				},
				stroke:{
					lineCap: 'round',
				},
				labels:props.values.map(t=>t.service.small_name),
				colors:['#498bd9', '#ffab2d', '#70b944','#6647bf', '#aa0000'],
            },
          
        };
    }

	render() {
		return (
			<>
			{this.props.values.length?<>
			<div id="chart">
				<ReactApexChart
				  options={this.state.options}
				  series={this.state.series}
				  type="radialBar"
				  height={320}
				/>
			</div>
				<div className="chart-content px-4 pt-0">	
					{this.props.values.map((item, i)=>{
							return <div key={item.id} className="d-flex justify-content-between mb-2 align-items-center">
						<div>
							<span className="fs-14">
								<span className="rounded-circle circle-badge" style={{background: this.state.colors[i]}}></span>
								{item.service.name}
							</span>
						</div>
						<div>
						<h5 className="mb-0">{item.balance}</h5>
						</div>
					</div>
					})}
					
					
				</div>	
			</>:undefined}
			</>
		);
	}
}

export default CurrentChart;
