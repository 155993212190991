import React from 'react';
import PersonalInfo from '../components/custom/personal-info.jsx'
import SettingsNav from '../components/custom/settings-nav';
import {useDispatch} from 'react-redux'
import Nav from "../layouts/nav";
import { ThemeContext } from "../../context/ThemeContext";

function Settings() {
    const { menuToggle } = React.useContext(ThemeContext);
    const dispatch = useDispatch()
    React.useEffect(() => {
       dispatch({type: "UPDATE_MENU", payload: "settings"})
    }, [dispatch])
 
    return (
        <>
           
           <div id="main-wrapper" className={` show ${
                menuToggle ? "menu-toggle" : ""
                }`}>
                <Nav/>
                <div className="content-body">
                <div className=" container-fluid">
                    <div className="row">
                        <div className="col-12" >
                            <SettingsNav active="profile"/>                            
                        </div>
                        <div className="col-xl-12">
                            <div className="row">
                                
                                <div className="col-xl-12"  data-aos="fade-up" data-aos-delay="400">
                                    <PersonalInfo></PersonalInfo>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                    
            </div>
                                                       
            
        </>
    )
}

export default Settings;