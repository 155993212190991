import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { createStore,  applyMiddleware, compose } from 'redux'
import rootReducer from './redux/reducers'
import {sessionService} from 'redux-react-session'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import logo from './images/logo-full.png'
import { CustomPreloader } from 'react-preloaders2';
import Bars from 'react-loader-spinner'
// init redux store
const mid = [thunk]
const store = createStore(rootReducer,
    compose(
    applyMiddleware(...mid),
    (window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : a=>a)
    ));
    
// validate session
const validateSession = (session) => { 
    return new Promise((res, rej)=>{
        if (!session) return rej(false)
        const refresh_time = +Object.keys(session).includes("refresh_time") && session.refresh_time;
        if(!refresh_time) return  rej("time session not found");
        if(new Date().getTime() - refresh_time>60*60*12*1000) {
          sessionService.deleteSession().then(e=>{
            return rej(false);
          })
        }
        return res(true);
    });
}
const options = { refreshOnCheckAuth: false, redirectPath: '/auth/login' };
sessionService.initSessionService(store, options)
 


ReactDOM.render(
  <>
  <Provider store={store}>
    <App />
    </Provider>
    </>
    ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
