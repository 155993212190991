import React, {useRef, useState} from "react";
import { Link } from "react-router-dom";
import Loader from 'react-loader-spinner'
import { toast } from "react-toastify";
import axios from "axios";
import {Constants} from '../../Constants'
import {useHistory} from 'react-router-dom'
// image
import logo from "../../images/logo-full.png";
import ReCAPTCHA from "react-google-recaptcha";
const Login = ({ history }) => {
  const phoneRef = useRef("")
    const passRef = useRef("")
    const rememberRef = useRef(false)
    const _history = useHistory()
    const [capcha, setCapcha] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const [passVisible, setPassVisible] = useState(false)
    const [sessionExpired, setSessionExpired] = useState(false)

    function onChange(value) {
      setCapcha(value)
    }

    const submitHandler = (e)=>{
        e.preventDefault()
        e.stopPropagation()
        setIsSubmitting(true)
        const data = {
            mobile: phoneRef.current.value,
            password: passRef.current.value,
            remember: rememberRef.current.value,
        }
        axios.post(Constants.BASE_URL + "/api/v2/token/otp/", data).then(response=>{
            const {data} = response
            localStorage.removeItem('otp_type')
            localStorage.removeItem('otp_id')
            localStorage.removeItem('otp_password')
            localStorage.removeItem('otp_phone')
            if(data.error === 1){
                toast.error(data.message)
            }else{
                
            
                localStorage.setItem('otp_type', 'login')
                localStorage.setItem('otp_id', data.id)
                localStorage.setItem('otp_password',passRef.current.value)
                localStorage.setItem('otp_phone',data.mobile)
                toast.success("در حال انتقال", {
                    onClose: ()=>{
                        _history.push("/auth/otp")
                    }
                })
            }
        }).catch(error=>{
          
            if (error.non_field_errors)
                toast.error(error.non_field_errors[0])
            else if(error.message){
              toast.error(error.message[0])
            }
            else if(error.password)
                toast.error(error.password[0])
            else if(error&&error.response&&Object.keys(error.response.data).length > 0){
                toast.error(error.response.data[Object.keys(error.response.data)[0]][0])
            }else if(error.response&&error.response.data.message){
              toast.error(error.response.data.message)
            }

        }).finally(f=>{
            setIsSubmitting(false)
        })
    }
    React.useEffect(() => {
      if(window.location.search.split("=")[1]!==undefined){
        setSessionExpired(true)
      }
    }, [])

  return (
    <div className="authincation ">
      <div className="container " data-aos="fade-up">
        <div className="row justify-content-center  pt-md-5 mt-5">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/">
                        <img src={logo} alt="" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">ورود به حساب کاربری</h4>
                    {sessionExpired?<p className="alert alert-danger py-2">
                      <small>نشست شما منقضی شده است ، لطفا دوباره وارد شوید.</small>
                    </p>:undefined}
                    <form onSubmit={(e) => submitHandler(e)}>
                      <div className="form-group">
                        <label className="mb-1 ">
                          <strong>شماره همراه</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control text-left"
                          ref={phoneRef}
                        />
                      </div>
                      <div className="form-group  position-relative">
                        <label className="mb-1 ">
                          <strong>رمز عبور</strong>
                        </label>
                        <input
                          type={passVisible? "text": "password"} 
                          className="form-control text-left"
                          ref={passRef}
                        />
                        <span  
                          style={{right: "8px", bottom: "14px"}}
                          className={"visible fa fa-" + (passVisible? "eye-slash": "eye")}
                          onClick={e=>setPassVisible(s=>!s)} ></span>

                      </div>
                       <ReCAPTCHA
                          sitekey="6Ld70yceAAAAAMBvP3Mje1DN1_JmRmtzjxEjHX0T"
                          onChange={onChange}
                          onExpired={e=>setCapcha(undefined)}
                          onErrored={e=>setCapcha(undefined)}
                          hl="fa"
                          
                        />
                      
                      <div className="form-row d-flex justify-content-between mt-4 mb-2">
                        <div className="form-group">
                          <div className="custom-control custom-checkbox ml-1 ">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="basic_checkbox_1"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="basic_checkbox_1"
                            >
                              به خاطر بسپار
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <Link className="" to="/auth/forget">
                            فراموشی رمز عبور 
                          </Link>
                        </div>
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                          disabled={!capcha  }
                        >
                        {isSubmitting? 
                            <Loader type={"ThreeDots"} height={30} width={35}  color={"white"}/>
                          :"ورود"
                          }
                          
                        </button>
                      </div>
                    </form>
                    <div className="new-account mt-3">
                      <p className="">
                        حساب جدید نیاز دارید؟{" "}
                        <Link className="text-primary" to="/auth/signup">
                          ثبت نام
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
