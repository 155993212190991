import logo from './logo.svg';
import './App.css';
import Markup from "./jsx";
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./icons/font-awesome-5/css/all.css";
import 'react-toastify/dist/ReactToastify.css';
import "./css/style.css";
import ThemeContextProvider from "./context/ThemeContext";
function App() {

  return (
    
      <ThemeContextProvider>
        <Markup />

      </ThemeContextProvider>
  );
}

export default App;
