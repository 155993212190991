import React, { useState, useRef } from "react";
import { Link, useHistory} from "react-router-dom";
import { toast, } from "react-toastify";
import axios from "axios";
import Loader from "react-loader-spinner";
import qs from 'qs'
import { Constants } from "../../Constants";
// image
import logo from "../../images/logo-full.png";

const Signup = () => {
  const [first_name, setFirstName] = useState("")
    const [last_name, setLastName] = useState("")
    const [mobile, setMobile] = useState("")
    const [password, setPassword] = useState("")
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const referralRef = useRef("")
    const [passVisible, setPassVisible] = useState(false)

    const history = useHistory();

    const submitHandler =(e)=>{
        e.preventDefault();
        e.stopPropagation();
        const validate = (data)=>{
            if(!first_name) return "نام نمیتواند خالی باشد"
            if(!last_name) return "نام خانوادگی نمیتواند خالی باشد"
            if(!mobile) return "موبایل نمیتواند خالی باشد"
            if(mobile.length !== 11) return "موبایل باید 11 رقمی باشد"
            if(!password) return "رمز عبور نمیتواند خالی باشد"
            if(password.length < 8) return "رمز عبور حداقل 8 کاراکتر"
            return undefined
        }
        const creds = {
            first_name,
            last_name,
            mobile,
            password
        }
        let errors = validate(creds)
        if (errors) {
            
            toast.warn(errors)
            return
        }
            setIsSubmitting(true);
            const d = qs.stringify({
                first_name,
                last_name,
                mobile,
                password,
                ref_mobile: referralRef.current.value
            })
            axios.post(Constants.BASE_URL + "/api/v2/token/register/", d , {headers: {"Content-Type": "application/x-www-form-urlencoded"}}).then(response=>{
                const {data} = response
                if(data.error!==1){
                    toast.success('کد یک بار مصرف ارسال شد.', {
                        autoClose: 2000,
                        onClose: ()=>{
                            localStorage.setItem("otp_type", "signup")
                            localStorage.setItem("otp_phone", mobile )
                            localStorage.setItem("otp_od", data.token )
                            history.push('/auth/otp')
                        }
                    });
                }else{
                    toast.warn(data.message)
                }
            }).catch(error=>{
                console.log(error);
                toast.error(error);
            }).finally(e=>{ 
                setIsSubmitting(false) 
            })
            
        
        
        // dispatch(userSignup(creds, history))
    }
    React.useEffect(() => {
      if(window.location.search.split("=")[1]!==undefined){
        referralRef.current.value = window.location.search.split("=")[1]
      }
    }, [])
  return (
    <div className="authincation"  data-aos="fade-up" data-aos-delay="000">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/">
                        <img src={logo} alt="" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">ایجاد حساب کاربری</h4>
                    <form onSubmit={(e) => submitHandler(e)}>
                      <div className="form-group">
                        <label className="mb-1 ">
                          <strong>نام</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={first_name}
                          onChange={e=>setFirstName(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label className="mb-1 ">
                          <strong>نام خانوادگی</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={last_name}
                          onChange={e=>setLastName(e.target.value)}
                        />
                      </div>
                     <div className="form-group">
                        <label className="form-label">شماره موبایل</label>
                        <input
                            type="text"
                            className="form-control text-left"
                            placeholder="09...."
                            name="number"
                            value={mobile}
                            dir="ltr"
                            onChange={e=>setMobile(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label className="form-label">کد معرف <small>(اختیاری)</small></label>
                        <input
                            type="text"
                            className="form-control text-left"
                            name="referral"
                            ref={referralRef}
                        />
                    </div>
                    <div className="form-group  position-relative">
                        <label className="form-label">رمز عبور</label>
                        <input
                            type={passVisible? "text": "password"}
                            className="form-control text-left"
                            placeholder=""
                            name="password"
                            value={password}
                            dir="ltr"
                            onChange={e=>setPassword(e.target.value)}
                        />
                        <span  
                          style={{right: "8px", bottom: "14px"}}
                          className={"visible fa fa-" + (passVisible? "eye-slash": "eye")}
                          onClick={e=>setPassVisible(s=>!s)} ></span>

                    </div>
                      <div className="text-center mt-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                        {isSubmitting? 
                            <Loader type={"ThreeDots"} height={30} width={35}  color={"white"}/>
                          :"ثبت نام"
                          }
                          
                        </button>
                      </div>
                    </form>
                    <div className="new-account mt-3">
                      <p className="">
                        حساب کاربری دارید؟{" "}
                        <Link className="text-primary" to="/auth/login">
                          ورود
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
