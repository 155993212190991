import React, { useContext } from "react";

/// React router dom
import { BrowserRouter as Router, Switch } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";
import {ToastContainer} from 'react-toastify'
/// Layout
import {Redirect} from 'react-router-dom'
import Login from "./pages/login.jsx";
import Signup from "./pages/signup.jsx";
import OTP from "./pages/otp.jsx";
import BuySell from "./pages/buy-sell.jsx";
import FastBuySell from "./pages/fast-buysell.jsx";
import Wallet from "./pages/wallet.jsx";
import Settings from "./pages/settings.jsx";
import Notifications from "./pages/notifications.jsx";
import SettingsAccount from "./pages/settings-account.jsx";
import SettingsInvite from "./pages/settings-invite.jsx";
import SettingsPassword from "./pages/settings-password.jsx";
import SettingsImages from "./pages/settings-images.jsx";
import Forget from "./pages/Forget.jsx";
import VerifyStep1 from "./pages/verify-step-1.jsx";
import VerifyStep2 from "./pages/verify-step-2.jsx";
import VerifyStep4 from "./pages/verify-step-4.jsx";
import AddDebitCard from "./pages/add-debit-card.jsx";
import AuthRoute from './routes/AuthRoute' 
import BasicRoute from './routes/BasicRoute' 
import Home from "./components/Dashboard/Home";
// import WhatsappIcon from './components/custom/whatsapp-icon';
import IndexTransactions from './pages/Transactions'
import OrderList from './pages/order'


import axios from 'axios'
import { sessionService } from 'redux-react-session' 
import { Constants } from '../Constants'
import { useDispatch, useSelector}from 'react-redux'
import { fetch_currencies,  fetch_user_all_data, toggle_loader_off} from '../redux/actions'
import Preloader from './pages/preloader';
import AOS from 'aos'

axios.interceptors.request.use(
    async config => {
        try{
            const session = await sessionService.loadSession()
            
            if (config.url.indexOf("service") === -1 && config.url.indexOf("token/otp") === -1 && session && session.token ) {
                config.headers['Authorization'] = 'Bearer ' + session.token;
            }
        }catch(err){
            console.log("inter1", err);
            return config
        }
        
        return config
        
    },
    error => {
        console.log("Inter", error);
        
        return Promise.reject(error) 
    }
);
axios.interceptors.response.use((response) => {
    return response
 }, 
 async (error)=> {
     const originalRequest = error.config
     if(error.response && error.response.status===400&&originalRequest.url.indexOf('/token/otp')>-1){
         return Promise.reject(error.response.data)
     }     
     const session = await sessionService.loadSession()
    if (error.response  && originalRequest.url
        .indexOf('refresh') >-1){
            sessionService.deleteSession().then(e=>{
                window.location.href = ('/auth/login?s=x');
                return Promise.reject(error);
            }).catch(e=>{
                console.log(e);
                return Promise.reject(error);
            })
        }
    if (error.response && error.response.status === 403  && originalRequest._retry ) {
        return Promise.reject()
    }
    if (error.response && error.response.status === 401  && !originalRequest._retry ) {
 
        originalRequest._retry = true;
        
        return axios.post(Constants.BASE_URL+'/api/v2/token/refresh/',
            {
              "refresh": session.refresh
            })
            .then(res => {
                if (res.status === 200) {
                    // 1) put token to LocalStorage
                    sessionService.saveSession({
                        token: res.data.access,
                        refresh: session.refresh,
                        refresh_time: new Date().getTime()
                    }).then(response=>{

                        // 2) Change Authorization header
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access
     
                        // 3) return originalRequest object with Axios.
                        // document.location.reload()
                        return axios(originalRequest);
                    })

 
                }
            }).catch(err=>{
                return Promise.reject(err)
            })
    }

    
 })


const Markup = () => {

   const {checked, authenticated, user} = useSelector(state => state.session)
    const loading = useSelector(state => state.preloader.loading)

    const dispatch = useDispatch()
    React.useEffect(() => {
        if(authenticated){
            dispatch(fetch_user_all_data())
            dispatch(fetch_currencies())
        }
    }, [authenticated, dispatch])

    React.useEffect(() => {
        AOS.init({
        duration : 500
        })
        AOS.refresh()
    }, [])

    React.useEffect(() => {
        sessionService.loadSession().then(session=>{
            if(!session) return
            axios.post(Constants.BASE_URL+'/api/v2/token/refresh/',
            {
              "refresh": session.refresh
            })
            .then(res => {
                if (res.status === 401) {
                    sessionService.deleteSession().then(e=>{
                        window.location.href = ('/auth/login?s=x');
                    }).catch(e=>{
                        console.log(e);
                    })
                }
            }).catch(err=>{
                return Promise.reject(err)
            }).finally(f=>{
                setTimeout(() => {
                    dispatch(toggle_loader_off())
                }, 2000);
            })
        }).catch(err=>{
            console.log(err);
            setTimeout(() => {
                    dispatch(toggle_loader_off())
                }, 2000);
            <Redirect to="/auth/login" />
            
        })
    }, [])
  return (
    <>
            <Router >{!loading&&checked?
            <>

                    <Switch>
                    <AuthRoute key={Math.random()}  exact path='/'> <Redirect to="/panel/dashboard"/></AuthRoute>
                    <AuthRoute  exact path='/panel/dashboard'> <Home/></AuthRoute>
                    <AuthRoute  exact path='/panel/sale'> <BuySell/></AuthRoute>
                    <AuthRoute  exact path='/panel/sale-onair'> <FastBuySell/></AuthRoute>
                    <AuthRoute  exact path='/panel/settings'> <Settings/></AuthRoute>
                    <AuthRoute  exact path='/panel/wallet'> <Wallet/></AuthRoute>
                    <AuthRoute  exact path='/panel/notifications'> <Notifications/></AuthRoute>
                    <AuthRoute  exact path='/panel/settings-account'> <SettingsAccount/></AuthRoute>
                    <AuthRoute  exact path='/panel/transactions'> <IndexTransactions/></AuthRoute>
                    <AuthRoute  exact path='/panel/orders'> <OrderList/></AuthRoute>
                    <AuthRoute  exact path='/panel/invite'> <SettingsInvite/></AuthRoute>
                    <AuthRoute  exact path='/panel/verify-step-1'> <VerifyStep1/></AuthRoute>
                    <AuthRoute  exact path='/panel/verify-step-2'> <VerifyStep2/></AuthRoute>
                    <AuthRoute  exact path='/panel/verify-step-4'> <VerifyStep4/></AuthRoute>
                    <AuthRoute  exact path='/panel/add-debit-card'> <AddDebitCard/></AuthRoute>
                    <AuthRoute  exact path='/panel/reset-password'> <SettingsPassword/></AuthRoute>
                    <AuthRoute  exact path='/panel/images'> <SettingsImages/></AuthRoute>
                    <BasicRoute  exact path='/auth/login'> <Login/> </BasicRoute>
                    <BasicRoute  exact path='/auth/signup'> <Signup/> </BasicRoute>
                    <BasicRoute  exact path='/auth/otp'> <OTP/> </BasicRoute>
                    <BasicRoute  exact path='/auth/forget'> <Forget/> </BasicRoute>
                    </Switch>
            
            <ToastContainer
                    position="bottom-left"
                    autoClose={2500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={true}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    light
                    />
            </>:<span/>}
        
        </Router>
        <Preloader loading={loading}/>
    </>
  );
};

export default Markup;
