import React from 'react'

import SettingsNav from '../components/custom/settings-nav';
import { useDispatch} from 'react-redux'
import ResetPassword from '../components/custom/ResetPassword';

import { ThemeContext } from "../../context/ThemeContext";
import Nav from "../layouts/nav";


function SettingsPassword() {
    const { menuToggle } = React.useContext(ThemeContext);

   
    const dispatch = useDispatch()
   
    React.useEffect(() => {
       dispatch({type: "UPDATE_MENU", payload: "settings"})
    }, [dispatch])
   
    return (
        <>

        <div id="main-wrapper" className={` show ${menuToggle ? "menu-toggle" : "" }`}>
            <Nav/>
            <div className="content-body">
                <div className=" container-fluid">
                    <div className="row">
                        <div className="col-12" >
                            <SettingsNav active="password"/>
                        </div>
                        <div className="col-12" data-aos="fade-up" data-aos-delay="200">
                            <ResetPassword />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
    </>
    )
}

export default SettingsPassword
