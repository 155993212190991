import React, { Fragment, useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import logo from "../../../images/logo-full.png";
import {useSelector, useDispatch} from 'react-redux'

const NavHader = () => {
  const { navigationHader, openMenuToggle, background } = useContext(
    ThemeContext
  );
  const isMenuOpen = useSelector(state => state.userManager.isMenuOpen)
  const dispatch = useDispatch()
  return (
    <div className="nav-header">
      <Link to="/" className="brand-logo">
        {background.value === "dark" || navigationHader !== "color_1" ? (
          <Fragment>
				<img src={logo} alt="t" style={{"width": "110px"}}/>
               
          </Fragment>
        ) : (
          <Fragment>
			<img src={logo} alt="t" style={{"width": "110px;"}}/>
               
          </Fragment>
        )}
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          dispatch({type: "UPDATE_SIDEBAR", payload: !isMenuOpen})
          openMenuToggle()
        }}
      >
        <div className={`hamburger ${!isMenuOpen ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
