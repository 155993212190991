import React, {useState, useEffect} from 'react'

import SettingsNav from '../components/custom/settings-nav';
import {Constants} from '../../Constants'
import axios from 'axios';
import Loader from 'react-loader-spinner'
import {Alert} from 'react-bootstrap'
import { useDispatch} from 'react-redux'

import { ThemeContext } from "../../context/ThemeContext";
import Nav from "../layouts/nav";


function SettingsInvite() {
    const { menuToggle } = React.useContext(ThemeContext);

    const [refData, setRefData] = useState([]) 
    const [loading, setLoading] = useState(false)
    const [copying, setCopying] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        setLoading(true)
        axios.get(Constants.BASE_URL+"/api/v2/account/referral/").then(response=>{
            const { data } = response
            setRefData(data)
        }).catch(error=>{
            console.log(error);
        }).finally(f=>{
            setLoading(false)

        })
    }, [])
    React.useEffect(() => {
       dispatch({type: "UPDATE_MENU", payload: "settings"})
    }, [dispatch])
    const copyToClipboard = (text)=>{
        setCopying(true)
        if (window.clipboardData && window.clipboardData.setData) {
            // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
            return window.clipboardData.setData("Text", text);
    
        }
        else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            var textarea = document.createElement("textarea");
            textarea.textContent = text;
            textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
            document.body.appendChild(textarea);
            textarea.select();
            try {
                return document.execCommand("copy");  // Security exception may be thrown by some browsers.
            }
            catch (ex) {
                console.warn("Copy to clipboard failed.", ex);
                return false;
            }
            finally {
                document.body.removeChild(textarea);
                setTimeout(() => {
                    setCopying(false)
                }, 2000);
            }
        }
        
        
    }
    return (
        <>

          <div id="main-wrapper" className={` show ${
                menuToggle ? "menu-toggle" : ""
                }`}>
                <Nav/>
                <div className="content-body">
                <div className=" container-fluid">
                <div className="row"  >
                    <div className="col-12">
                        <SettingsNav active="invite" />
                    </div>
                    <div className="col-12">
                        <div className="row">
                           
                            <div className="col-xl-12">
                                <div className="card" data-aos="fade-up" >
                                    <div className="card-header">
                                        <h4 className="card-title">فعالیت دوستان</h4>
                                    </div>
                                    
                                    {
                                    loading?
                                        <Loader type="Oval" color="#00BFFF" className="p-4" height={50} width={50} />:   
                                    refData  ?
                                    <div className="table-responsive">
                                        <table className="table table-responsive-sm px-2 fs-6">
                                            <thead>
                                                <tr>
                                                    <th>تعداد افراد دعوت شده توسط شما</th>
                                                    <th>میزان درآمد شما</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <td>{refData.user_count}</td>
                                                <td>{refData.total_get}</td>
                                            </tbody>
                                        </table>
                                        </div>:
                                        <p className="p-4">تا کنون دعوتی نداشته اید</p>
                                    }
                                    <div className="card-footer">
                                        <p>لینک دعوت شما: </p>
                                        <Alert  variant={"primary"} className='mb-1 text-left  bg-transparent text-success  border-gray'>
                                            {Constants.REFERRAL_BASE + (refData.referral_code || 123456 )}
                                        </Alert>
                                        <div onClick={e=>copyToClipboard(Constants.REFERRAL_BASE + (refData.referral_code || 123456 ))} className="pb-3 text-success px-2 mb-3">
                                            <small style={{cursor: "pointer"}}>کپی لینک</small>
                                        </div>
                                        {copying?<small className="text-info">کپی شد</small>: undefined}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>

        
    </>
    )
}

export default SettingsInvite
