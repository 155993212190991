import React, { useState, useEffect} from "react";
import { Link, useHistory } from "react-router-dom";
import { userLogin, userSignup, userForget, resendToken } from '../../redux/actions'
import Loader from 'react-loader-spinner'
import { connect, useDispatch } from 'react-redux'
import {toast} from 'react-toastify'
import axios from 'axios'
import { Constants } from "../../Constants";
// image
import logo from "../../images/logo-full.png";

const OTP = ({ history, userLogin }) => {
    const [otpType, setOtpType] =  useState("login")
    const [otpId, setOtpId] =  useState("3d8bcf51-3649-4bf4-ab6e-71b6fbd732de")
    const [otpPhone, setOtpPhone] =  useState("")
    const [otpPass, setOtpPass] =  useState("")
    
    const dispatch = useDispatch()
    const [code, setCode]= useState("");
    const [timer, setTimer] = useState(60)
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const _history = useHistory()
    useEffect(()=>{
        // ref.current.focus()
        const type = localStorage.getItem("otp_type") || "login"
        if(type) setOtpType(type)
        const id = localStorage.getItem("otp_id")
        if(id) setOtpId(id)
        const phone = localStorage.getItem("otp_phone")
        if(phone) setOtpPhone(phone)
        const pass = localStorage.getItem("otp_password")
        if(pass) setOtpPass(pass)
    }, [])
    
    setTimeout(e=>{
        if(timer > 0 ) 
        setTimer(timer-1)
    }, 1000)
    

    const resetAuth = e=>{
      if(otpType === "login"){
        const data = {
            mobile: otpPhone,
            password: otpPass,
        }
        axios.post(Constants.BASE_URL + "/api/v2/token/otp/", data).then(response=>{
          const {data} = response
          if(data.error === 1){
              toast.error(data.message)
          }else{
            toast.success("کد یکبار مصرف ارسال شد")
            setTimer(60)
          }
        }).catch(error=>{
            if (error.non_field_errors)
                toast.error(error.non_field_errors[0])
            else if(error.message){
              toast.error(error.message[0])
            }
            else if(error.password)
                toast.error(error.password[0])
            else if(error&&error.response&&Object.keys(error.response.data).length > 0){
                toast.error(error.response.data[Object.keys(error.response.data)[0]][0])
            }else if(error.response&&error.response.data.message){
              toast.error(error.response.data.message)
            }

        }).finally(f=>{
            setIsSubmitting(false)
        })
      }
          
      else if(otpType === 'signup'){
        dispatch(resendToken({id: otpId})).then(({status, message})=>{
          if(status === 200){
            toast.success(message)
            setTimer(60)
          }else{
            toast.error(message)
          }
        })
      }
          
      else if(otpType === "forget"){
        setIsSubmitting(true)
        axios.post(Constants.BASE_URL + "/api/v2/token/password/", {
            mobile: otpPhone
        }).then(response=>{
            const {data} = response
            if(data.error === 0){
                setTimer(60)
                toast.success(data.message);
            }else{
                toast.warn(data.message);
            }
        }).catch(error=>{
            toast.error(' برای این شماره همراه ، حسابی وجود ندارد.');
        }).finally(()=>{ 
            setIsSubmitting(false) 
        })
      }
          
        
    }
    const verifyCode = e=>{
        e.preventDefault()
        e.stopPropagation()
        if(code.length !== 5) return
        setIsSubmitting(true);
        
        switch (otpType) {
            case "login":
                userLogin( {
                    id: otpId,
                    otp: code,
                }, _history, setIsSubmitting )
                break
            case "signup":
                dispatch(userSignup({
                    mobile: otpPhone, 
                    code: code
                }, _history, setIsSubmitting))
                break
            case "forget":
                dispatch(userForget({
                    mobile: otpPhone, 
                    code: code
                }, _history, setIsSubmitting))
                break
            
            default: return
            
        }
       
    }

  return (
    <div className="authincation " data-aos="fade-up">
      <div className="container ">
        <div className="row justify-content-center  pt-5 mt-5">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/">
                        <img src={logo} alt="" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 "> احراز هویت با رمز یکبار مصرف</h4>
                    <form onSubmit={(e) => verifyCode(e)}>
                      <div className="form-group">
                        <label className="mb-1 ">
                          <strong>کد 5 رقمی</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control text-center"
                          value={code}
                          maxLength={5}
                          style={{letterSpacing: "12px", fontSize: "1.3rem"}}
                          onChange={e=>setCode(e.target.value)}
                          placeholder="12345"
                        />
                      </div>
                      
                      
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          {isSubmitting? 
                            <Loader type={"ThreeDots"} height={30} width={35}  color={"white"}/>
                          :"تایید"
                          }
                        </button>
                      </div>
                    </form>
                    <div className="new-account mt-3 d-flex justify-content-between">
                          {timer > 0?
                              <small>امکان ارسال دوباره بعد از 
                                  <span className="text-primary px-2">{timer}</span>    
                              ثانیه</small>
                              :
                              <p>
                                  دریافت نکردید؟{" "}
                              
                                  <button  className="text-primary bg-transparent border-0"
                                      onClick={resetAuth}>ارسال دوباره</button>
                              </p>    
                          }
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null,{userLogin})(OTP);

