import React from 'react'
import Bars from 'react-loader-spinner'
function Preloader(props) {
    return (
        <>
            <div id="preloader_screen" data-aos="fade-up" style={{transition: `0.3s all`}}className={`${props.loading? 'd-flex' :'deactive'}`}>
                <img src={require("../../images/logo-full.png").default} className="mb-3" alt="logo-loader"></img>           
                <Bars />
            </div>
  
        </>
    )
}

export default Preloader
