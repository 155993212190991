import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {  mark_notifications_asread } from '../../redux/actions';
import Loader from 'react-loader-spinner'
import TimeAgo from 'react-timeago'
import farsiStrings from 'react-timeago/lib/language-strings/fa'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import Nav from "../layouts/nav";
import { ThemeContext } from "../../context/ThemeContext";

function Notifications() {
    const formatter = buildFormatter(farsiStrings)
    const {notificationList:notifications, updating_notifications} = useSelector(state=>state.notifications)
    const dispatch = useDispatch()
    const { menuToggle } = React.useContext(ThemeContext);

    useEffect(() => {
        dispatch(mark_notifications_asread())
        dispatch({type: "UPDATE_MENU", payload: "settings"})
    }, [dispatch])
    
    return (
        <>
        

            <div id="main-wrapper" className={` show ${
                menuToggle ? "menu-toggle" : ""
                }`}>
                <Nav/>
                <div className="content-body">
                <div className="p-0 container-fluid">
                   <div className="row p-2 p-md-5">
                        <div className="col-xl-12">
                        <div className="card mb-0" data-aos="fade-up">
                                <div className="card-header border-0">
                                    <h4 className="card-title">اعلان ها</h4>
                                </div>
                                <div className="card-body pt-0">
                                    <div className="transaction-table">
                                        <div className="table-responsive">
                                        {notifications && notifications.length?
                                            <table className="table mb-0 table-responsive-sm">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>متن </th>
                                                        <th>نوع</th>
                                                        <th>تاریخ</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    
                                                        {notifications.map((notif, idx)=>{
                                                            return  <tr key={idx}>
                                                                <>
                                                                    <td>{idx+1}</td>
                                                                    <td>{notif.text}</td>
                                                                   
                                                                    <td>
                                                                        <span className="badge badge-success">{notif.type}</span>
                                                                    </td>
                                                                    <td> <small><TimeAgo date={notif.published} formatter={formatter} /> </small></td>
                                                                   
                                                                    </>
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </table>: updating_notifications?
                                                <Loader type="Oval" color="#00BFFF" height={50} width={50} />:
                                            <p>اعلانی ندارید</p>

                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                        </div>
                    </div>
                </div>
            </div>

            </div>

            
        </>
    )
}

export default Notifications;