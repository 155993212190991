import React, { } from 'react';
import { Link } from 'react-router-dom';
import { Tab, Nav} from 'react-bootstrap'



function SettingsNav({active="profile"} ) {
 const tabData = [
    
     ["ویرایش پروفایل", "profile", "/panel/settings"],
     ["تصاویر", "images", "/panel/images"],
     ["حساب ها", "accounts", "/panel/settings-account"],
     ["دعوت دوستان", "invite", "/panel/invite"],
     ["تغییر رمزعبور", "password", "/panel/reset-password"],
     
    ]
    return (
        <>
            <div className="card settings_menu">
                <Tab.Container defaultActiveKey={active}>
                  <Nav as="ul" className="nav-pills  light">
                    {tabData.map((data, i) => (
                      <Nav.Item as="li" key={i}>
                        <Link className={"nav-link "+ (data[1]===active? "active": "")} eventKey={data[1]} to={data[2]} role="tab" data-rb-event-key={data[1]}>
                          {data[0]}
                        </Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </Tab.Container>
               
            </div>
        </>
    )
}

export default SettingsNav;