import React,{Fragment,Component,useState} from 'react';

import IndexTransactions from "../custom/index-transactions";
import Convert from '../custom/convert'
import HomeChartPrices from '../custom/homechartprices'
import CurrentChart from '../zenix/Home/CurrentChart'
import HomeChart from '../custom/HomeChart'

//Images

import {useSelector} from 'react-redux'
import Nav from "../../layouts/nav";
import { ThemeContext } from "../../../context/ThemeContext";





const orderdataBlog = [
	{price: '82.1', amount: '58.9', total: '134.10',},
	{price: '85.2', amount: '55.8', total: '136,12',},
	{price: '87.3', amount: '53.7', total: '138,12',},
	{price: '89.4', amount: '51.6', total: '139,12',},
	{price: '91.9', amount: '47.1', total: '140,12',},
	{price: '93.8', amount: '46.2', total: '142,12',},
	{price: '94.7', amount: '45.3', total: '145,12',},
	{price: '97.6', amount: '44.4', total: '147,12',},
];


const Home = () => {
	
	const wallet = useSelector(state => state.wallet.wallet)
	const chart_wallet = useSelector(state => state.wallet.chart_wallet)
	const { menuToggle } = React.useContext(ThemeContext);
	const delay = useSelector(state=>state.preloader.loading)
		return(
			<>
			<div id="main-wrapper" className={` show ${
                menuToggle ? "menu-toggle" : ""
                }`}>
                <Nav/>
                <div className="content-body">
                <div className="container-fluid">
				<section data-aos="fade-up" data-aos-delay={delay?"3000":"0"}><Convert /></section>
				<section data-aos="fade-up" data-aos-delay={delay?"3100":"100"}><HomeChartPrices/></section>
				<div className="row">
					<div className="col-xl-9 col-xxl-8">
						<section data-aos="fade-up" data-aos-delay={delay?"3200":"200"}><HomeChart /></section>
					</div>
					<div className="col-xl-3 col-xxl-4">
						<section data-aos="fade-up" data-aos-delay={delay?"3300":"300"}>
							<div className="card">
								<div className="card-header border-0 pb-0">
									<h4 className="fs-20 text-black">کیف پول</h4>
								</div>
								<div className="card-body pb-0 px-2">
								{chart_wallet.length? 
										<>
											<div id="currentChart" className="current-chart px-2 mb-0">
											<CurrentChart values={chart_wallet} />
										</div>
										
										</>
										:"درحال بروزرسانی کیف پول"
									}
									
								</div>
							</div>
						</section>
					</div>
				</div>
				<div className="row">
					
						
					<div className="col-12">
					<section data-aos="fade-up" data-aos-delay={delay?"3300":"300"}>
						<IndexTransactions />
						</section >
					</div>	
							
				</div>
				</div>
				</div>
			</div>
			</>
		)
	
}
export {orderdataBlog};
export default Home;