import React from 'react'
import {useSelector} from 'react-redux'
function HomeChartPrices() {
	
	const currs = ["BNB", "SOL", "XRP", "DOT"]
    
	const services = useSelector(state => state.currencies.currencyList)
    const [currency1, setCurrency1] = React.useState(0)
    const [currency2, setCurrency2] = React.useState(0)
    const [currency3, setCurrency3] = React.useState(0)
    const [currency4, setCurrency4] = React.useState(0)


    React.useEffect(() => {
        if(services.length &&services.filter(i=>i.small_name_slug===currs[0]).length)
            setCurrency1(services.filter(i=>i.small_name_slug===currs[0])[0].buyPrice)
        if(services.length &&services.filter(i=>i.small_name_slug===currs[1]).length)
            setCurrency2(services.filter(i=>i.small_name_slug===currs[1])[0].buyPrice)
        if(services.length &&services.filter(i=>i.small_name_slug===currs[2]).length)
            setCurrency3(services.filter(i=>i.small_name_slug===currs[2])[0].buyPrice)
        if(services.length &&services.filter(i=>i.small_name_slug===currs[3]).length)
            setCurrency4(services.filter(i=>i.small_name_slug===currs[3])[0].buyPrice)
    }, [services])
    return (
        <div className="row">
                    <div className="col-xl-3 col-sm-6 m-t35">
						<div className="card card-coin">
							<div className="card-body text-center">
								<svg className="mb-3 currency-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="none"><circle cx="16" cy="16" r="16" fill="#F3BA2F"/><path fill="#FFF" d="M12.116 14.404L16 10.52l3.886 3.886 2.26-2.26L16 6l-6.144 6.144 2.26 2.26zM6 16l2.26-2.26L10.52 16l-2.26 2.26L6 16zm6.116 1.596L16 21.48l3.886-3.886 2.26 2.259L16 26l-6.144-6.144-.003-.003 2.263-2.257zM21.48 16l2.26-2.26L26 16l-2.26 2.26L21.48 16zm-3.188-.002h.002V16L16 18.294l-2.291-2.29-.004-.004.004-.003.401-.402.195-.195L16 13.706l2.293 2.293z"/></g></svg>
                                <h2 className="text-black mb-2 font-w600">${currency1}</h2>
								
							</div>
						</div>
					</div>
					<div className="col-xl-3 col-sm-6 m-t35">
						<div className="card card-coin">
							<div className="card-body text-center">
								<svg className="mb-3 currency-icon" width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g fill="none"><circle fill="#66F9A1" cx="16" cy="16" r="16"/><path d="M9.925 19.687a.59.59 0 01.415-.17h14.366a.29.29 0 01.207.497l-2.838 2.815a.59.59 0 01-.415.171H7.294a.291.291 0 01-.207-.498l2.838-2.815zm0-10.517A.59.59 0 0110.34 9h14.366c.261 0 .392.314.207.498l-2.838 2.815a.59.59 0 01-.415.17H7.294a.291.291 0 01-.207-.497L9.925 9.17zm12.15 5.225a.59.59 0 00-.415-.17H7.294a.291.291 0 00-.207.498l2.838 2.815c.11.109.26.17.415.17h14.366a.291.291 0 00.207-.498l-2.838-2.815z" fill="#FFF"/></g></svg>
								<h2 className="text-black mb-2 font-w600">${currency2}</h2>
							</div>
						</div>
					</div>
					
					<div className="col-xl-3 col-sm-6 m-t35">
						<div className="card card-coin">
							<div  className="card-body text-center">
							<svg className="mb-3 currency-icon" width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g fill="none"><circle cx="16" cy="16" r="16" fill="#23292F"/><path d="M23.07 8h2.89l-6.015 5.957a5.621 5.621 0 01-7.89 0L6.035 8H8.93l4.57 4.523a3.556 3.556 0 004.996 0L23.07 8zM8.895 24.563H6l6.055-5.993a5.621 5.621 0 017.89 0L26 24.562h-2.895L18.5 20a3.556 3.556 0 00-4.996 0l-4.61 4.563z" fill="#FFF"/></g></svg>												<h2 className="text-black mb-2 font-w600">${currency3}</h2>
							</div>
						</div>
					</div>
					<div className="col-xl-3 col-sm-6 m-t35">
						<div className="card card-coin">
							<div className="card-body text-center">
							<svg className="mb-3 currency-icon" width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g fill="none"><circle fill="#E6007A" cx="16" cy="16" r="16"/><path d="M16.272 6.625c-3.707 0-6.736 3.012-6.736 6.736 0 .749.124 1.48.356 2.192a.95.95 0 001.194.589.95.95 0 00.588-1.194 4.745 4.745 0 01-.267-1.73c.071-2.512 2.103-4.58 4.616-4.704a4.86 4.86 0 015.115 4.847 4.862 4.862 0 01-4.58 4.848s-.945.053-1.408.125c-.232.035-.41.071-.535.089-.054.018-.107-.036-.09-.09l.161-.783.873-4.028a.934.934 0 00-.712-1.105.934.934 0 00-1.105.713s-2.103 9.802-2.121 9.909a.934.934 0 00.713 1.105.934.934 0 001.105-.713c.017-.107.303-1.408.303-1.408a2.367 2.367 0 011.996-1.854 21.43 21.43 0 011.051-.089 6.744 6.744 0 006.22-6.719c0-3.724-3.03-6.736-6.737-6.736zm.481 15.505a1.122 1.122 0 00-1.336.873c-.125.606.25 1.212.873 1.337a1.122 1.122 0 001.337-.874c.124-.623-.25-1.212-.874-1.336z" fill="#FFF"/></g></svg>								
							<h2 className="text-black mb-2 font-w600">${currency4}</h2>
							</div>
						</div>
					</div>
				</div>
    )
}

export default HomeChartPrices
