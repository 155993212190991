import React  from "react";
import ProfileImages from "../components/custom/profile-images";
import {Redirect} from 'react-router-dom'
import {useSelector} from 'react-redux'
function VerifyStep1() {
    const user = useSelector(state=>state.session.user)

    return (
        <>
        {user && user.scans && user.scans.selfie && user.scans.national_card && user.scans.birth_certificate ?
            <Redirect to={"/panel/verify-step-2"}></Redirect>:undefined
        }
        <section  data-aos="fade-up" data-aos-delay="000">
        <ProfileImages/>
        </section>

        </>
    );
}

export default VerifyStep1;
