import React from 'react'
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import {Dropdown} from 'react-bootstrap'
import axios from 'axios'
import MarketOverviewChart from "../zenix/Home/MarketOverviewChart"

function HomeChart() {
    const [currs, setcurrs] = React.useState(["BTC", "ETH", "DOGE", "ADA"])
    
    const [crrency1, setCrrency1] = React.useState("Monthly")
    const [currency1, setCurrency1] = React.useState(true)
    const [currency2, setCurrency2] = React.useState()
    const [currency3  , setCurrency3] = React.useState()
    const [currency4, setCurrency4] = React.useState()
    const [chartData, setchartData] = React.useState({})
    const [key, setKey] = React.useState({})
    React.useEffect(() => {
        let c = ""
        if(currency1) c+= currs[0]
        if(currency2){
            if(c) c+=","
            c+=currs[1]
        }
        if(currency3){
            if(c) c+=","
            c+=currs[2]
        }
        if(currency4){
            if(c) c+=","
            c+=currs[3]
        }
        
        axios.get('http://82.115.25.87:8000/api/v1/historical/multi/?s='+(c||"BTC"))
        .then(res=>{
            const {data} = res
            setchartData(data)
            setKey(Math.random())
        }).catch(err=>console.log(err))
    }, [crrency1, currency1,currency2, currency3, currency4])
    return (
        <div className="card">
            <div className="card-header border-0 flex-wrap pb-0 select-btn">
                <div className="mb-3 w-100">
                    <h4 className="fs-20 text-black">نمودار</h4>
                    
                </div>
                <br/>
                <div className="d-flex flex-wrap mb-2 justify-content-between w-100">
                    <div className="custom-control check-switch custom-checkbox  mb-2">
                        <input type="checkbox" checked={currency1} className="custom-control-input" id="customCheck9" value={currency1} onChange={e=>setCurrency1(e.target.checked)}/>
                        <label className="custom-control-label" htmlFor="customCheck9">
                            <span className="d-block  font-w500 mt-2">{currs[0]}</span>
                        </label>
                    </div>
                    <div className="custom-control check-switch custom-checkbox  mb-2">
                        <input type="checkbox" className="custom-control-input" id="customCheck92" value={currency2} onChange={e=>setCurrency2(e.target.checked)}/>
                        <label className="custom-control-label" htmlFor="customCheck92">
                            <span className="d-block font-w500 mt-2">{currs[1]}</span>
                        </label>
                    </div>
                    <div className="custom-control check-switch custom-checkbox  mb-2">
                        <input type="checkbox" className="custom-control-input" id="customCheck91" value={currency3} onChange={e=>setCurrency3(e.target.checked)}/>
                        <label className="custom-control-label" htmlFor="customCheck91">
                            <span className="d-block  font-w500 mt-2">{currs[2]}</span>
                        </label>
                    </div>
                    <div className="custom-control check-switch custom-checkbox  mb-2">
                        <input type="checkbox" className="custom-control-input" id="customCheck93" value={currency4} onChange={e=>setCurrency4(e.target.checked)}/>
                        <label className="custom-control-label" htmlFor="customCheck93">
                            <span className="d-block font-w500 mt-2">{currs[3]}</span>
                        </label>
                    </div>
                </div>
                
            </div>
            <div className="card-body pb-0">
                <MarketOverviewChart data={chartData} key={key}/>
                
            </div>
        </div>
    )
}

export default HomeChart
