import React from 'react'

import SettingsNav from '../components/custom/settings-nav';

import { useDispatch} from 'react-redux'
import ProfileImages from '../components/custom/profile-images'
import { ThemeContext } from "../../context/ThemeContext";
import Nav from "../layouts/nav";


function SettingsImages() {
    const { menuToggle } = React.useContext(ThemeContext);

   
    const dispatch = useDispatch()
   
    React.useEffect(() => {
       dispatch({type: "UPDATE_MENU", payload: "settings"})
    }, [dispatch])
   
    return (
        <>

        <div id="main-wrapper" className={` show ${menuToggle ? "menu-toggle" : "" }`}>
            <Nav/>
            <div className="content-body">
                <div className=" container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <SettingsNav active="images"/>
                        </div>
                        <div className="col-xl-12">
                                <div className="card"  data-aos="fade-up" data-aos-delay="0">
                                    <div className="card-header">
                                        <h4 className="card-title">تصاویر کاربر</h4>
                                    </div>
                                    <ProfileImages source="settting"/>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>

        
    </>
    )
}

export default SettingsImages
